<template>
  <brands-table
    :headers="headers"
    :clients="clients"
    @getid="delete_Client($event)"
  />
</template>
<script>
import BrandsTable from "../../../components/Tables/BrandsTable.vue";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "client_name",
        width: "300px",
      },
      { text: "Agency", value: "agency", width: "170px" },
      { text: "Contact", value: "contact_email", width: "250px" },
      {
        value: "actions",
        width: "130px",
        align: "end",
      },
    ],
  }),
  components: {
    BrandsTable,
  },

  computed: {
    clients() {
      return this.$store.state.clients;
    },
  },

  async mounted() {
    await this.readClients();
  },
  methods: {
    async delete_Client(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("delClient", id);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "client deleted successfully!",
          group: "success",
        });

        await this.readClients();
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async readClients() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readClients");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>