<template>
  <v-card id="brands">
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            rounded
            dense
            height="1"
            color="#3371E2"
          ></v-text-field>
        </v-col>
        <v-col cols="9">
          <span class="float-right">
            <v-btn
              small
              depressed
              outlined
              color="#8cc73e"
              class="custom-transform-class"
              to="/users-management/add-client"
              >Add Client</v-btn
            >
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="clients"
      :search="search"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.client_name="{ item }">
        <h5>{{ item.client_name }}</h5>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.agency="{ item }">
        <h5 class="mt-3">{{ item.agency ? item.agency.agency_name : null }}</h5>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          class="mr-5"
          color="blue"
          icon
          @click="updateClient(item.id)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn small color="red" icon @click="client_delete(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions
      class="d-flex justify-space-between align-center pa-4"
      v-if="clients.length"
    >
      <v-btn outlined class="font-transform-inherit" color="#C6C5C5" small>
        <span class="dashboard-blue">Showing page {{ page }}</span>
      </v-btn>
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
        prev-icon="mdi-arrow-left"
        next-icon="mdi-arrow-right"
        color="#3371E2"
        class="custom"
        :total-visible="7"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    search: "",
  }),
  props: {
    headers: Array,
    clients: Array,
  },
  methods: {
    updateClient(id) {
      this.$router.push({
        name: "update-client",
        params: {
          id: id,
        },
      });
    },
    client_delete(id) {
      this.$emit("getid", id);
    },
  },
};
</script>

<style scoped>
#brands {
  margin: 2.5rem 0;
}
</style>